@font-face {
    font-family: telegraf;
    src: url("./../fonts/telegraf/Telegraf-Regular.ttf");
    font-weight: normal;
}
@font-face {
    font-family: telegraf;
    src: url("./../fonts/telegraf/Telegraf-Bold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: telegraf;
    src: url("./../fonts/telegraf/Telegraf-Medium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: telegraf;
    src: url("./../fonts/telegraf/Telegraf-Light.ttf");
    font-weight: 300;
}
@font-face {
    font-family: telegraf;
    src: url("./../fonts/telegraf/Telegraf-SemiBold.ttf");
    font-weight: 600;
}
$font-primary: telegraf;
$font-secondary: telegraf;
