.lds-facebook {
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 8rem;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  width: 1rem;
  background: $color-primary;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 0;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 2rem;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 4rem;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 0.8rem;
    height: 6.4rem;
  }
  50%,
  100% {
    top: 2.4rem;
    height: 3.2rem;
  }
}
