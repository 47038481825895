/*
// Nacelle Booking Colors
$color-bg: #000000;
$color-bg-secondary: #000000;
$color-bg-overlay: black;
$color-text-primary: #FFFFFF;
$color-text-secondary: black;
$color-text-tertiary: #FFFFFF;
$color-primary: #FFFFFF;
$color-secondary: #FF82D2;
$color-tertiary: #33A8FF;
$color-disabled: #4d4d4d;
*/

// Sandbox Booking Colors
$color-bg: #0C2366;
$color-bg-secondary: #0C2366;
$color-bg-overlay: black;
$color-text-primary: #E0E0D6;
$color-text-secondary: #A1BEFF;
$color-text-tertiary: #FFFFFF;
$color-primary: #E0E0D6;
$color-secondary: #A1BEFF;
$color-tertiary: #FF642E;
$color-disabled: #4d4d4d;
