@import "branding-colors";
$color-overlay: #e5e5e5;
$color-overlay-dark: #000000;
$color-grey-1: #c5c5c5;
$color-grey-2: #c4c4c4;
$color-grey-3: #666666;
$color-grey-4: #a0a0a0;
$color-grey-5: #ccc;
$color-grey-6: #f5f5f5;
$color-grey-7: #bdbdbd;
$color-grey-8: #f9f9f9;
$danger: red;
$success: #1db954;
$warning: #ffcc00;
